<template>
    <div class="oeffnungszeiten">
        <header class="page-header pt-1 pb-5">
            <div class="page-header-content">
                <div class="container">
                    <div class="row justify-content-center">
                        <img src="../assets/img/flower.svg" style="width:50px;" class="float-left mr-3"/>
                        <h1>Öffnungszeiten</h1>
                    </div>
                    <aktuelle-oeffnungszeiten class="currently-open"/>
                </div>
            </div>
            <div class="svg-border-rounded text-light">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none"
                     fill="currentColor">
                    <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                </svg>
            </div>
        </header>
        <section class="bg-light py-10">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <h2 class="underline mb-4">Jänner bis März</h2>
                        <table class="table text-left mb-4">
                            <tbody>
                            <tr>
                                <th scope="row">Montag - Donnerstag</th>
                                <td>08:00-12:00 und 15:00-18:00</td>
                            </tr>
                            <tr>
                                <th scope="row">Freitag</th>
                                <td>07:30-12:00 und 13:00-18:00</td>
                            </tr>
                            <tr>
                                <th scope="row">Samstag</th>
                                <td>07:30-12:30</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-lg-6">
                        <h3 class="mb-4 mt-4">Valentinstag</h3>
                        <table class="table text-left mb-4">
                            <tbody>
                            <tr>
                                <th scope="row">Dienstag, 14.2.2024</th>
                                <td>7:30-18:00</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr class="mb-4"/>
                <div class="row">
                    <div class="col-lg-6">
                        <h2 class="underline mb-4">April und Mai</h2>
                        <table class="table text-left mb-4">
                            <tbody>
                            <tr>
                                <th scope="row">Montag - Donnerstag</th>
                                <td>08:00-12:00 und <strong>13:00</strong>-18:00</td>
                            </tr>
                            <tr>
                                <th scope="row">Freitag</th>
                                <td>07:30-12:00 und 13:00-18:00</td>
                            </tr>
                            <tr>
                                <th scope="row">Samstag im April</th>
                                <td>07:30-12:30</td>
                            </tr>
                            <tr>
                                <th scope="row">Samstag im Mai</th>
                                <td>07:30-<strong>15:00</strong></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-lg-6">
<!--                        <h3 class=" mb-4 mt-4">Tag der Gärtnereien in NÖ</h3>-->
<!--                        <table class="table text-left mb-4">-->
<!--                            <tbody>-->
<!--                            <tr>-->
<!--                                <th scope="row">Samstag, 24.4.2021<br/></th>-->
<!--                                <td>07:30-17:00</td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                        </table>-->

<!--                        <h3 class=" mb-4 mt-4">Aktionstage</h3>-->
<!--                        <table class="table text-left mb-4">-->
<!--                            <tbody>-->
<!--                            <tr>-->
<!--                                <th scope="row">Samstag, 24.4.2021<br/></th>-->
<!--                                <td>07:30-17:00</td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                        </table>-->

                        <h3 class=" mb-4 mt-4">Tag der Arbeit</h3>
                        <table class="table text-left mb-4">
                            <tbody>
                            <tr>
                                <th scope="row">Sonntag, 1.5.2024</th>
                                <td>09:00-11:30</td>
                            </tr>
                            </tbody>
                        </table>
                        <h3 class="mb-4 mt-4">Muttertag</h3>
                        <table class="table text-left mb-4">
                            <tbody>
                            <tr>
                                <th scope="row">Samstag, 11.5.2024</th>
                                <td>07:30-17:00</td>
                            </tr>
                            <tr>
                                <th scope="row">Sonntag, 12.5.2024</th>
                                <td>09:00-11:30</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr class="mb-4"/>
                <div class="row">
                    <div class="col-lg-6">
                        <h2 class="underline mb-4">Juni bis Dezember</h2>
                        <table class="table text-left mb-4">
                            <tbody>
                            <tr>
                                <th scope="row">Montag - Donnerstag</th>
                                <td>08:00-12:00 und 15:00-18:00</td>
                            </tr>
                            <tr>
                                <th scope="row">Freitag</th>
                                <td>07:30-12:00 und 13:00-18:00</td>
                            </tr>
                            <tr>
                                <th scope="row">Samstag</th>
                                <td>07:30-12:30</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="svg-border-rounded text-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none"
                     fill="CurrentColor">
                    <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                </svg>
            </div>
        </section>
    </div>
</template>
<script>
    import AktuelleOeffnungszeiten from "../components/AktuelleOeffnungszeiten";

    export default {
        components: {AktuelleOeffnungszeiten}
    }
</script>

<style scoped>

    .currently-open {
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
    }

</style>